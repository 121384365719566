.mainMenuContainer{
    position: fixed;
    width:190px;
    left: 0;
    top:50%;
    background-color: #dea725;
    z-index: 1000;
    transform: translateY(-50%);

}

.marquee {
    min-height: 350px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
   
  }
  
  .marquee--inner {
    display: block;
    margin: 70px 0;
    position: absolute;
    animation: marquee 12s linear infinite;
  }
  
  .marquee--inner:hover {
    animation-play-state: paused;
  }
 
  
  
  @keyframes marquee {
    0% { top: 0; }
    100% { top: -200%; }
  }
.logoContainerMain{
    margin-left: 3px;
    margin-right: 10px;
}
.logoMain{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 0;
}
.menuListMain ul{
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.menuListMain ul li{
    list-style: none;
    padding-left: 10px;
    color:#fff;
    border-bottom: 1px solid brown;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
}
.menuListMain ul li.activeMnu{
    color:#fff;
    background:#4b4b4b;
    border-bottom: 1px solid #4b4b4b;
}
.HomeSubmenuContainer{
    position: fixed;
    width:240px;
    left: -200px;
    top:50%;
    background-color: #ffffff;
    transition: all .3s ease;
    z-index: 999;
    transform: translateY(-50%);
}
.activeSubMenu{
    left:25%;
}
.HomeSubmenuFullContainer{
    position: fixed;
    width:50%;
    left: -51%;
    top:50%;
    background-color: #ffffff;
    transition: all .3s ease;
    z-index: 999;
    transform: translateY(-50%);
}
.activeSubMenu{
    left:190px;
}
.menuListSub ul{
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.menuListSub ul li{
    list-style: none;
    padding-left: 10%;
    color:#4b4b4b;
    border-bottom: 1px solid #939393;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
}
.menuListSub ul li.activeMnu{
    border-bottom: 1px solid #4b4b4b;
}
.HomeSubmenuThirdContainer{
    position: fixed;
    width:250px;
    left: -285px;
    top:50%;
    background-color: #d7d7d7;
    transition: all .5s ease;
    z-index: 995;
    transform: translateY(-50%);
}
.activeThirdMenu{
    left:430px;
}
.menuListSublist ul li.activeMnuSub{
    background:#d7d7d7;
    border-bottom: 1px solid #4b4b4b;
}
.news p {
    font-size: 13px;
}
.pinkTextColor{
    color:#dea725;
}
.eventsList{
    padding: 30px;
}
.eventsList ul{
    padding: 0;
    list-style: none;
}
.eventsList ul li{
    border-bottom: 1px solid #939393;
    padding-top: 15px;
    padding-bottom: 15px;
}
.eventsList ul li p{
    margin-bottom: 0;
    font-size: 13px;
}
.contactDetails p{
    margin-bottom: 5px;
}
.formGroup label{
    font-size: 13px;
}
.formGroup input{
    width: 100%;
    margin-bottom: 10px;
    font-size: 13px;
    min-height: 30px;
}
.submitBtn {
    background: #dea725;
    border: none;
    width: 100px;
    height: 30px;
    color: #fff;
}
.map-responsive{
    overflow:hidden;
    padding-bottom:75%;
    position:relative;
    height:0;
}

.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
.bannerText {
    position: absolute;
    bottom: 15%;
    background: rgb(0 0 0 / 65%);
    width: fit-content;
    color: #fff;
    max-width: 30%;
    left: 220px;
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 15px;
}

.marqueeText{
   
    background: rgb(0 0 0 / 65%);
    color: #fff;
    max-width: 100%;
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 15px;
}
.bannerHeightLabel{
    max-height: 100vh;
    overflow-y: hidden;
}
