.patchTwoIconContainer{
    width:75%;
    margin-left: 21%;
    padding-top: 2em;
    padding-bottom: 1em;
}
.patchTwoIconContainer p{
    margin-bottom: 20px;
    text-align: center;
}
.homeIcon{
    width:70px;
}
.paddingSpace{
    padding-top:4em;
    padding-bottom:4em;
}
.titleHome{
    font-size: 19px;
    padding-bottom: 10px;
}
@media only screen and (max-width: 780px){
.patchTwoIconContainer {
    margin-left: 0;
    padding-bottom: 1em;
    padding-top: 1em;
    width: 100%;
}
.homeIcon {
    width: 45px!important;
}
}
@media only screen and (max-width: 780px){
    .paddingSpace {
        padding-top: 1em;
        padding-bottom: 1em;
    }
    }