.img_res{
    width:100%;
}
.paddingclassHostel {
    padding: 2em 0px !important;
}
.fontbig {
    font-size: 28px;
    color: white;
    text-align: center;
    font-weight: bolder;
    font-style: emphasiz;
    font-style: italic;
    line-height: 41px;
}
.bg2 {
    background: #023b5e !important;
}
.paraparaHostel{
    font-size:16px;
    text-align:justify;
    line-height:26px;
    color:#000 !important;
    margin:1em 0px !important;
}
.fontbig2hostel{
    font-size: 32px;
    color: #023b5e;
    text-align: center;
    font-weight: bold;
    margin-bottom:1em;
}
.HOSTELBG{
    background-image: url(../../assets/images/hostelimg/Artboard2.png) ;
    background-size: cover;
}
.contactbgHostel{
     background-image: url(../../assets/images/hostelimg/lastbg.jpg) ;
    background-size: cover;
    height:50vh;
}
.carouselmargin {
    margin-top: -12em;
}
.hostelbox {
    padding: 2em 2em 0px 2em;
    text-align: center;
    height: 30vh;
       background: #e3431ecc;
    margin: 1em;
        opacity: 0.9;
}
.hightlightimg {
    width: 50%;
    margin-bottom: 2%;
}
.bbapar3 {
    font-size: 14px;
    color: white !important;
    text-align: center;
}
.img_res{
    width:100%;
}
.roombox{
    background: white;
    padding: 2%;
    width:68%;
    font-size: 22px;
    position: relative;
    text-align: center;
    bottom: 10%;
    left: 16%;
}


.roombox2 {
    background: white;
    padding: 2%;
    width: 80%;
    font-size: 22px;
    text-align: center;
    position: relative;
    bottom: 10%;
    left: 11%;
}
.roombox3 {
    background: white;
    padding: 2%;
    width:91%;
    font-size: 22px;
    text-align: center;
    position: relative;
    bottom: 10%;
    left: 5%;
}
.HOSTELBG2{
    background:#E3431E;
}
.boxhostel{
    padding:2em;
}
.paddingclassroom{
    padding:3em 0px;
    margin-top:10em;
}
.carouselmargin{
    margin-top:-12em;
}
.carousel-indicators {
    bottom: -28px !important;
}
.fontbig3{
    font-size: 32px;
    color: #023b5e;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1em;
    margin-top:2em;
}


.unlimitedfont {
    font-size: 28px;
   
    /* text-align: center; */
    font-weight: bold;
    padding: 0px 11%;
    margin-bottom: 0em;
    color: white;
}
.para2{
    padding: 1em 2em !IMPORTANT;
    font-size: 18px;
    color:white !IMPORTANT;
    margin-bottom:1em !important;
}
.boxgray{
        padding: 0px;
    background: gray;
    min-height:82vh !important;
}
.boxblue{
    padding: 0px;
    background: #023b5e;
    width:70%;
    margin-left:2%;
     min-height:82vh !important;
}
.packagefont {
    font-size: 28px;
    color: white;
    text-align: left;
    font-weight: bold;
    margin-bottom: 1em;
    margin-left: 2em;
}
.para3{
    font-size: 18px ;
    padding: 0em 2em 1em 3em !IMPORTANT;
    color: white !IMPORTANT;
    line-height: 32px;
    margin-top:-1% !important;
}
.img_res1{
    width:100%;
    height:47vh;
   
}
.img_rescon{
    width:100%;
}
.spanfont{
    font-size:20px;
    color: white;
}
.margincon{
    margin-bottom:2em;
}

.contactfont{
    font-size: 32px;
    text-align: center;
    margin-top: 15%;
    font-weight: bold;
    color: white;
}
.paddingtop{
    padding-top:2em;
}
.mhide{
    display:block;
}
.mshow{
    display:none;
}
.paddingclassnew{
    padding:2em !important;
}

@media only screen and (max-width: 680px) {
    .mhide{
    display:none;
}
.mshow{
    display:block;
}
    .hostelbox {
    padding: 1em 1em 0px 1em;
    text-align: center;
    height: 27vh;
    background: #e3431ecc;
    margin: 1em;
    opacity: 0.9;
    width: 40%;
}
.fontbig2hostel {
    font-size: 22px;
    color: #023b5e;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1em;
}
.paddingclassHostel {
    padding: 1em 0px !important;
}
.fontbig {
    font-size: 22px;
    color: white;
    text-align: center;
    font-weight: bolder;
    font-style: emphasiz;
    font-style: italic;
    line-height: 29px;
    padding: 0px 1em;
}
.fontbig3 {
    font-size: 22px;
    color: #023b5e;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0em;
    margin-top: 7%;
}
.para2 {
    padding: 1em 2em !IMPORTANT;
    font-size: 16px;
    color: white !IMPORTANT;
    margin-bottom: 1em !important;
    text-align: center;
}
.boxgray {
    padding: 0px;
    background: gray;
    min-height: auto;
}
.img_res1 {
    width: 100%;
    height: auto;
}
.unlimitedfont {
    font-size: 22px;
    /* text-align: center; */
    font-weight: bold;
    padding: 0px 9%;
    margin-bottom: 0em;
    text-align: center;
    color: white;
}
.boxblue {
    padding: 0px;
    background: #023b5e;
    width: 100%;
    margin-left: 0%;
    min-height: 70vh !important;
}
.packagefont {
    font-size: 22px;
    color: white;
    text-align: left;
    font-weight: bold;
    margin-bottom: 1em;
    margin-left: 0em;
    text-align: center;
}
.para3 {
    font-size: 14px;
    padding: 0em 1em 1em 1em !IMPORTANT;
    color: white !IMPORTANT;
    line-height: 28px;
    margin-top: 0% !important;
    text-align: justify;
}
.contactfont {
    font-size: 22px;
    text-align: center;
    margin-top: 7%;
    font-weight: bold;
    color: white;
}
.margincon {
    margin-bottom: 3em;
}
.spanfont {
    font-size: 14px;
    color: white;
}
}







@media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)  
{
    
    .boxgray {
    padding: 0px;
    background: gray;
    min-height: 100vh !important;
}
.img_res1{
    width:100%;
    height:71vh;
   
}
.para2{
    padding: 1em 2em !IMPORTANT;
    font-size: 18px;
    color:white !IMPORTANT;
    margin-bottom:3em !important;
}
    
    
}
