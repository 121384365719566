.faculty-col {
    position: relative;
    animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
    background: white;
    /* width: 48%;
    margin: 10px; */
    min-height: 227px;
    border-top: 0px solid transparent;
    border-left: 0px solid #023b5e;
    border-bottom: 0px solid transparent;
    overflow: hidden;
    margin-bottom:15px;
}

.faculty-col img {
    width: 100%;
    border-radius: 15% !important;
    position: relative;
    top: 55px;
    z-index: 1;
    border: 2px solid white;
}

.faculty-col::after {
    content: '';
    display: block;
    width: 250px;
    height: 600px;
    background: #F2C165;
    position: absolute;
    animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both !important;
}

.faculty-btn>a {
    font-size: 13px;
    font-weight: bold;
    color:#023b5e;
    text-decoration:none !important;
}


@keyframes rotatemagic {
    0% {
        opacity: 0;
        transform: rotate(0deg);
        top: -24px;
        left: -253px;
    }

    100% {
        transform: rotate(-30deg);
        top: -24px;
        left: -78px;
    }
}

.faculty-col img {
    width: 100%;
    border-radius: 75%;
    position: relative;
    top: 18px;
    z-index: 1;
    border: 2px solid white;
}

.faculty-img {
    width: 100%;
    border-radius: 75%;
    position: relative;
    top: 55px;
    z-index: 1;
    border: 2px solid white;
}
.packageNew {
    background: #f2c165;
    padding: 2px 10px;
    font-weight: bold;
    color: black;
}
@media only screen and (max-width: 680px) {
    .faculty-col {
        position: relative;
        animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
        background: white;
        width: 100%;
        margin: 10px;
        min-height: 170px;
        border-top: 0px solid transparent;
        border-left: 0px solid #023b5e;
        border-bottom: 0px solid transparent;
        overflow:
            hidden;
    }
}