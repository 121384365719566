@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.arrowboxText{
    position: relative;
    background: #FFD502;
    }
    .arrowboxText p {
    color:#ffffff;padding:1px 5px 3px 5px;
    font-family: "Montserrat", sans-serif;
    font-size:1vw;
    color:#535353;
    padding:5px;
    font-weight:500;
    }
    .arrowboxText:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left-color: #FFD502;
    border-width: 17px;
    margin-top: -16px;
    }
    .paddingForSection{
    padding:4em 1em;
    }
    .ZeroPadding{
    padding:0px;
    }
    .BgBanner{
    background: #2358a8  0% 0% no-repeat padding-box;
    background-image:url("../../assets/images/landingPageImages/bannerLanding.png");
    width: 100%;
    background-size: contain;
    height: 80vh;
    background-repeat: no-repeat;
    }
    .Bgstudent{
    background-image:url("../../assets/images/landingPageImages/studentBanner.png");
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    }
    .tringleImge{
    width: 20% !important;
    margin-top: -7%;
    margin-left: -6px;
    }
    .tringleImge1{
    width: 20% !important;
    margin-top: -7%;
    margin-right: -1px;
    float: right;
    z-index: 999;
    position: relative;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    }
    .topmargin{
    margin-top:5%;
    }
    .BgBanner h1{
    font-size:2.5vw;
    color:#FFFFFF;
    font-weight:700;
    font-family: "Montserrat", sans-serif;
    margin:0px;
    }
    .BgBanner h2{
    font-size:1.4vw;
    color:#FFD502;
    font-weight:300;
    font-family: "Montserrat", sans-serif;
    margin-top:5px;
    }
    .SpecialisationsBg h2{
    font-size:2.5vw;
    color:#FFFFFF;
    font-weight:700;
    font-family: "Montserrat", sans-serif;
    margin:0px;
    text-align:center;
    }
    .SpecialisationsBg{
    background:#FF9A02;
    height:100vh;
    }
    .bghalfOrage{
    background:#FF9A02;
    }
    .admissionWorking img{
    width:100%;
    }
    .admissionWorking {
    width:100%;
    
    background: linear-gradient(to bottom, #FF9A02 80%, #2358A8  20%);
    }
    .tabBox2{
        box-shadow: 1px 10px 34px 6px #80808094 !important;
    }
    .admissionWorking h1{
    font-size: 2.5vw;
    color: #FFFFFF;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin: 0px;
    text-align: center;
    padding: 0px 20px;
    margin-bottom: 5%;
    }
    
    .Suppernumary h2 {
        font-size: 1.0vw;
        /*color: #FFFFFF;*/
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        margin: 0px;
        /* text-align: center; */
        /* padding: 0px 20px; */
        margin-bottom: 2%;
        margin-top: 2%;
        color: #FFD502;
    }
    
    
    .admissionWorking h1 span{
    font-size: 2.5vw;
    color: #FFFFFF;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin: 0px;
    text-align: center;
    padding: 0px 0px;
    margin-bottom: 0%;
    margin-top: 0%;
    font-style: italic;
    }
    .SpecialisationsBoxOuter {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* width: 100%; */
    /* flex-wrap: wrap; */
    font-size: 2em;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "card-1 card-2 card-3 card-4 card-5";
    text-align: center;
    }
    .SpecialisationsBoxInner {
    /* font-size: 2em; */
    /* display: grid; */
    /* grid-gap: 1em; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
    /* grid-template-areas: "card-1 card-2 card-3 card-4 card-5"; */
    padding: 20px 10px;
    margin-top: 3%;
    text-align: center;
    width: 20%;
    }
    .border-bottom{
    border-bottom:1px solid #8888;
    }
    .SpecialisationsBoxInner img{
    width:30%;
    }
    .SpecialisationsBoxInner p{
    font-size:1.2vw;
    color:#FFF;
    font-weight:500;
    font-family: "Montserrat", sans-serif;
    margin-top:25px;
    text-align: center !important;
    }
    .WhyChooseUsbg h2{
    font-size:2vw;
    color:#170D65;
    font-weight:700;
    font-family: "Montserrat", sans-serif;
    margin:0px;
    text-align:center;
    }
    .WhyChooseUsbox{
    margin:2% 0px;
    }
    .WhyChooseUsbox h2{
    text-align:left !important;
    font-size:1.6vw !important;
    }
    .WhyChooseUsbox p{
    font-size:1.2vw;
    color:#170D65;
    font-weight:500;
    font-family: "Montserrat", sans-serif;
    margin-top:10px;
    }
    .sectionThings{
    position: relative;
    margin-top:2%;
    margin-bottom:1%;
    }
    .imageAfter{
    text-align: center;
    margin-top: 1%;
    }
    .imageAfter img{
    z-index: 1;
    position: relative;
    width: 51%;
    }
    .imageAfter::before {
        content: '';
        display: inline-block;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        background-color: #FFD502;
        position: absolute;
        margin-left: -4%;
        margin-top: 1%;
    }
    .tabBox{
    background: #fff;
    padding: 0px;
    border-radius: 0px 0px 80px 80px;
    width: 92%;
    margin: 4%;
    box-shadow: 1px 10px 34px 6px gray;
    }
    .tabBoxButton>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    width: 15em !important;
    /* width: 100%; */
    font-size: 1.4vw !important;
    text-align: center !important;
    color:#8995ab !important;
    border-right:2px solid #f5f5f5;
    padding:20px;
    font-family: "Montserrat", sans-serif;
    }
    .tabBoxButtons>li.active>a, .tabBoxButtons>li.active>a:focus, .tabBoxButton>li.active>a:hover {
    cursor: default;
    background-color: #2358a8 !important;
    border: 0px solid #ddd!important;
    color: white !important;
    border-bottom-color: transparent !important;
    font-family: "Montserrat", sans-serif;
    }
    .tabBoxButtons>li.active>a {
    cursor: default;
    background-color: #2358a8 !important;
    border: 0px solid #ddd!important;
    color: white !important;
    border-bottom-color: transparent !important;
    font-family: "Montserrat", sans-serif;
    }
    .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #2358a8 !important;
    border: 0px solid #ddd!important;
    color: white !important;
    border-bottom-color: transparent !important;
    font-family: "Montserrat", sans-serif;
    }
    .tabBoxButtons {
    border-bottom: 0px solid #ddd;
    }
    .TabcantentBox{
    padding: 3em 6em;
    }
    .TabcantentBox h3{
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 1.5vw !important;
    color: #8995ab !important;
    margin-bottom: 15px;
    }
    .TabcantentBox p{
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 1vw !important;
    color: #8995ab !important;
    margin-bottom: 15px;
    }
    .TabcantentBox h2{
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 2vw !important;
    color: #2358a8 !important;
    margin-bottom: 15px;
    font-weight:700;
    }
    .bgCourseofferd{
    background-image:url("../../assets/images/landingPageImages/cOfferd1.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 20px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 3%;
    }
    .bgtraningofferd{
    }
    .outerbgtraningofferd{
    padding: 30px 20px;
    background: #e04b10;
    width: 48%;
    margin: 1%;
    }
    .bgtraningofferd img{
    width: 100%;
    margin-top: 12%;
    }
    .TabcantentBox h4{
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 1.5vw !important;
    color: #fff !important;
    margin-bottom: 15px;
    font-weight:500;
    }
    .h2text img{
    width: 13%;
    margin-right: 4%;
    }
    .h2text {
    text-align:center!important
    }
    .lifontcertificate li {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 1.2vw !important;
    color: #8995ab !important;
    margin-bottom: 11px;
    }
    .arrowboxText1 p {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 1.5vw !important;
    color: #2358a8 !important;
    margin-bottom: 15px;
    padding: 10px 4%;
    
    margin-left: 4%;
    }
    .arrowboxText1:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left-color: #FFD502;
    border-width: 26px;
    margin-top: -26px;
    }
    .arrowboxText1 {
    position: relative;
    background: #FFD502;
    }
    .TabcantentBox h5{
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 1.2vw !important;
    color: #8995ab !important;
    margin-bottom: 24px;
    }
    .marginminus{
    margin-top:-27%;
    }
    .tableDiv .table-bordered>tbody>tr>td, .tableDiv .table-bordered>tbody>tr>th, .tableDiv .table-bordered>tfoot>tr>td, .tableDiv .table-bordered>tfoot>tr>th, .tableDiv.table-bordered>thead>tr>td, .tableDiv.table-bordered>thead>tr>th {
    border: 1px solid #8995ab;
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 1.2vw !important;
    color: #8995ab !important;
    margin-bottom: 15px;
    }
    .marginBottom{
        margin-bottom:5%;
    }
    
    .tabBoxButton .nav-item button{
        margin-right: 2px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
        width: 16em !important;
        /* width: 100%; */
        font-size: 1.4vw !important;
        text-align: center !important;
        color: #8995ab !important;
        border-right: 2px solid #f5f5f5;
        padding: 20px 2px;
        font-family: "Montserrat", sans-serif;
    }

    .tabBoxButton.nav-tabs .nav-link.active
{
        background-color: #2358a8 !important;
        border: 0px solid #ddd !important;
        color: white !important;
        border-bottom-color: transparent !important;
        font-family: "Montserrat", sans-serif;
    }
    @media only screen and (max-width: 600px) {
        .tabBoxButton .nav-item button {
            margin-right: 2px;
            line-height: 1.42857143;
            border: 1px solid transparent;
            border-radius: 4px 4px 0 0;
            width: 23em !important;
            /* width: 100% !important; */
            font-size: 4vw !important;
            text-align: center !important;
            color: #8995ab !important;
            border-right: 2px solid #f5f5f5;
            padding: 20px;
            font-family: "Montserrat", sans-serif;
        }

    .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border: 1px solid #8995ab;
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 14px !important;
    color: #8995ab !important;
    margin-bottom: 15px;
    }
    .BgBanner {
    background: #2358a8  0% 0% ;
    background-image: url("../../assets/images/landingPageImages/bannerLanding.png");
    width: 100%;
    background-size: cover;
    height: 41vh;
    background-repeat: no-repeat;
    }
    .arrowboxText p {
    color: #ffffff;
    padding: 1px 5px 3px 5px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #535353;
    padding: 5px;
    font-weight: 500;
    }
    .arrowboxText:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left-color: #FFD502;
    border-width: 15px;
    margin-top: -15px;
    }
    .topmargin {
    margin-top: 0%;
    }
    .paddingForSection {
    padding: 2em 0em;
    }
    .BgBanner h1 {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin: 0px;
    }
        .BgBanner h2 {
            font-size: 15px;
            color: #FFD502;
            font-weight: 300;
            font-family: "Montserrat", sans-serif;
            margin-top: 5px;
            margin-bottom: 10%;
            width: 75%;
            /* margin: 4px; */
        }
        
      .BgBannerh2 {
            font-size: 15px;
            font-weight: 300;
            font-family: "Montserrat", sans-serif;
            width: 100%;
            text-align: center;
            margin: 10px;
        }
    
    .sppudiv {    
            color: #555;
        cursor: default;
        background-color: #3d34be !important;
        border: 0px solid #ddd !important;
        color: white !important;
        border-bottom-color: transparent !important;
        font-family: "Montserrat", sans-serif;
    }
    
    .SpecialisationsBg h2 {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin: 0px;
    text-align: center;
    }
    .SpecialisationsBoxInner {
            padding: 17px 10px;
            margin-top: 6%;
            text-align: center;
            width: 40%;
            height: 17vh;
            margin-left: 5%;
        }
    .SpecialisationsBoxInner img {
    width: 40%;
    }
    .SpecialisationsBoxInner p {
    font-size: 14px;
    color: #FFF;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin-top: 25px;
    text-align: center !important;
    }
    .SpecialisationsBg {
    background: #FF9A02;
    height: 76vh;
    }
    .tabBoxButton>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    width: 20em !important;
    /* width: 100%; */
    font-size: 16px !important;
    text-align: center !important;
    color: #8995ab !important;
    border-right: 2px solid #f5f5f5;
    padding: 20px;
    font-family: "Montserrat", sans-serif;
    }
    .TabcantentBox h3 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 16px !important;
    color: #8995ab !important;
    margin-bottom: 15px;
    }
    .TabcantentBox p {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 14px !important;
    color: #8995ab !important;
    margin-bottom: 15px;
    }
    .TabcantentBox {
    padding: 1em 2em 2em 2em;
    }
    .TabcantentBox h2 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 18px !important;
    color: #2358a8 !important;
    margin-bottom: 15px;
    font-weight: 700;
    }
    .TabcantentBox h4 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 16px !important;
    color: #fff !important;
    margin-bottom: 15px;
    font-weight: 500;
    }
    .bgCourseofferd {
    background-image: url(../../assets/images/landingPageImages/cOfferd1.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 21px 8px;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 3%;
    }
    .outerbgtraningofferd {
    padding: 30px 20px;
    background: #e04b10;
    width: 99%;
    margin: 1%;
    text-align: center;
    }
    .bgtraningofferd img {
    width: 17%;
    margin-top: 0%;
    }
    .lifontcertificate li {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 14px !important;
    color: #8995ab !important;
    margin-bottom: 11px;
    }
    .WhyChooseUsbg h2 {
    font-size: 18px;
    color: #170D65;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin: 0px;
    text-align: center;
    }
    .imageAfter::before {
    content: '';
    display: inline-block;
    width: 66px;
    height: 66px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 50%;
    background-color: #FFD502;
    position: absolute;
    margin-left: -8%;
    margin-top: 4%;
    }
    .imageAfter img {
    z-index: 1;
    position: relative;
    width: 21%;
    }
    .WhyChooseUsbox h2 {
    text-align: center !important;
    font-size: 17px !important;
    margin-top: 4%;
    }
    .WhyChooseUsbox p {
    font-size: 14px;
    color: #170D65;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
    text-align: center;
    }
    .admissionWorking h1 {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin: 0px;
    text-align: center;
    padding: 0px 20px;
    margin-bottom: 5%;
    margin-top: -2%;
    }
    .admissionWorking h1 span {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin: 0px;
    text-align: center;
    padding: 0px 0px;
    margin-bottom: 0%;
    margin-top: 0%;
    font-style: italic;
    }
    .TabcantentBox h5 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-size: 14px !important;
    color: #8995ab !important;
    margin-bottom: 24px;
    }
    }
    
    