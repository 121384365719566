.bannerTitleAbout{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 58%;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 45px;
}
.bannerCont{
    position: relative;
}
@media only screen and (max-width: 780px) {
    .bannerTitleAbout {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 43%;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 18px;
    }
  }