body{
    font-family: 'Mulish', sans-serif;
    
}

.blinkfont {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
    text-align: right;
}

.blinkanimation{
    color: red !important;
    animation: blinkfont 1s linear infinite;
}
@keyframes blinkfont{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}


.ulpaddingleft{
    padding-left: 25px;
}
.placemetBox{
    width: 20%;
}
.popupImg{
    width: 60%;
}
.uTubemarginT{
    margin-top:25px;
}
.imgRes{
    width:100%;
}
.somi{
    padding-left:0 ;
    padding-right:0 ;
}
.Textleft{
    text-align: left;
}
a {
    text-decoration: none;
}
p {
    text-align: justify;
}

.paddingZero{
    padding: 0;
}
.lightGreyContainer{
    background: #d7d7d7;
}
.orangeContainer{
    background: #dea725;
}
.whiteBackground{
    background: #ffffff;
}
.padding30px{
    padding: 30px;
}
.footer{
    background-color: #dea725;
    padding-top:2em;
    padding-bottom:2em;
}
.footerLogo{
    width:65%;
}
.socialMediaLogo{
    padding: 0;
    display: flex;
}
.socialMediaLogo li{
    list-style: none;
}
.socialMediaLogo li img{
    width:30px;
    margin-right: 15px;
    cursor: pointer;
}
.innerPageMnuIcon {
    width: 55px;
    margin-top: 20px;
    cursor: pointer;
}
.text-right{
    text-align: right;
}

.text-center{
    text-align: center;
}
.innerPageLogo {
    max-width: 430px;
    padding: 7px;
    padding-left: 0;
}
.blockTitle{
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: bold;
}
.blockTitle span{
    color: #dfa726;
}
.borderimg1 { 
    border-left: 5px solid transparent;
    padding: 15px;
    border-image: url(assets/images/about/directorline.jpg) 30 stretch;
    box-shadow: 0 1px 7px #abaaaa;
}
.borderimg2 { 
    border-right: 5px solid transparent;
    padding: 5px;
    border-image: url(assets/images/about/directorline.jpg) 30 stretch;
    box-shadow: 0 1px 7px #abaaaa;
}
.borderimg3 { 
    border-right: 5px solid transparent;
    border-image: url(assets/images/about/directorline.jpg) 30 stretch;
}
.padding5px{
    padding: 5px;
}
.semiTitle {
    font-size: 16px;
    margin-top: 15px;
}
.semiTitleInner {
    font-size: 14px;
    margin-bottom: 0;
}
.marginBottom3em{
    margin-bottom: 3em;
}
.marginBottom2em{
    margin-bottom: 2em;
}
.backgroundGrey{
    background-color: #e7e9e9;
    padding-bottom: 3em;
    padding-top: 3em;
}
.backgroundGreyPlain{
    background-color: #e7e9e9;
}
.whiteBgWithShadow{
    background-color: #fff;
    box-shadow: 0 2px 7px #c0c0c0;
}
.roundWithShadow{
    background-color: #fff;
    box-shadow: 0 2px 7px #c0c0c0;
    border-radius: 10px;
}
.positionRelative{
    position: relative;
}
.box1MinHeight{
    min-height: 100px;
}
.circleJourney{
    width: 138px;
    position: absolute;
    margin-left: -30px;
    margin-top: -12px;
}
.circleJourneySmall{
    width: 138px;
    position: absolute;
    margin-left: -30px;
    margin-top: -12px;
}
.circleAboutIcon {
    width: 112px;
    position: absolute;
    margin-top: 13px;
    margin-left: 22px;
}
.positionAbsolute{
    position: absolute;
}
.paddingLeft70px{
    padding-left: 70px;
}
.paddingBottom {
    padding-bottom: 10px;
}
.boxShadow{
    box-shadow: 0 1px 5px #c0c0c0;
}
.paddingTop {
    padding-top: 15px;
}
.buttonStyle1{
    width: 100px;
    background: #dea725;
    border: none;
    color: #fff;
    font-size: 13px;
    height: 30px;
    margin-bottom: 20px;
}
.padding5px {
    padding-left: 5px;
    padding-right: 5px;
}
.font14 {
    font-size: 14px;
}
.font12 {
    font-size: 12px;
}
.padding15px{
    padding: 15px;
}
.lightPinkBackground{
    background-color: #f9e7ef;
}
.lightBlueBackground{
    background-color: #daf0f4;
}
.paddingCommonbox {
    padding-left: 70px;
    padding-top: 8px;
    padding-bottom: 10px;
}
.maxWidthIcon{
    max-width: 70px;
}
.iconBoxMinHeight{
    min-height: 216px;
}
.marginTop1em{
    margin-top: 1em;
}
.marginTop2em{
    margin-top: 2em;
}
.marginTop3em{
    margin-top: 3em;
}
.darkGreyBg{
    background-color: #d7d7d7;
}
.paddingCommonbox1 {
    padding-left: 70px;
    padding-top: 23px;
    padding-bottom: 11px;
}
.circleAboutIcon1 {
    width: 100px;
    position: absolute;
    margin-top: 0px;
    left: 39px;
}
.contact .form-control {
    padding: 0.59rem 0.75rem;
}
.submitBtnContact {
    background: #dea725;
    border: none;
    width: 150px;
    height: 47px;
    color: #fff;
}
.fulMenuMain{
    position: fixed;
    width:30%;
    left:0;
    top:0;
    height:100vh;
    background-color: #dea725;
    z-index: 9999;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
}
.paddingBottom3em {
    padding-bottom: 3em;
}
.paddingTop3em {
    padding-top: 3em;
}
.fulMenuMain ul{
    padding: 0;
}
.fulMenuMain ul li{
    list-style: none;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 8px;
    border-bottom: 1px solid #939393;
    padding-top: 8px;
    color: #fff;
    cursor: pointer;
}
.fulMenuMain ul li i{
    float: right;
}
.rightAlignBannerTxt {
    text-align: right!important;
    padding-left: 5%;
}














.mainMenuContainerInnerPage{
    position: fixed;
    width:25%;
    left: 0;
    top:50%;
    background-color: #dea725;
    z-index: 1000;
    transform: translateY(-50%);

}
.logoContainerMainInnerPage{
    margin-left: 20%;
    margin-right: 20px;
}
.logoMainInnerPage{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}
.menuListMainInnerPage ul{
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.menuListMainInnerPage ul li{
    list-style: none;
    padding-left: 20%;
    color:#fff;
    border-bottom: 1px solid #939393;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}
.menuListMainInnerPage ul li.activeMnuInnerPage{
    color:#fff;
    background:#4b4b4b;
    border-bottom: 1px solid #4b4b4b;
}
.HomeSubmenuContainerInnerPage{
    position: fixed;
    width:30%;
    left: -51%;
    top:50%;
    background-color: #ffffff;
    transition: all .3s ease;
    z-index: 999;
    transform: translateY(-50%);
    height: 100vh;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
}
.activeSubMenuInnerPage{
    left:30%;
}
.HomeSubmenuFullContainerInnerPage{
    position: fixed;
    width:30%;
    left: -51%;
    top:50%;
    background-color: #ffffff;
    transition: all .3s ease;
    z-index: 999;
    transform: translateY(-50%);
}
.menuListSubInnerPage ul{
    padding: 0; 
    margin-top: 0;
    margin-bottom: 0;
}
.menuListSubInnerPage ul li{
    list-style: none;
    padding-left: 0;
    color:#4b4b4b;
    border-bottom: 1px solid #939393;
    padding-top: 10px;
    padding-bottom: 10px;
}
.menuListSubInnerPage ul li.activeMnuInnerPage{
    border-bottom: 1px solid #4b4b4b;
}
.HomeSubmenuThirdContainerInnerPage{
    position: fixed;
    width:25%;
    left: -1%;
    top:50%;
    background-color: #d7d7d7;
    transition: all .5s ease;
    z-index: 995;
    transform: translateY(-50%);
}
.activeThirdMenuInnerPage{
    left:50%;
}
.menuListSublistInnerPage ul li.activeMnuSubInnerPage{
    background:#d7d7d7;
    border-bottom: 1px solid #4b4b4b;
}
.newsInnerPage p {
    font-size: 13px;
}
.pinkTextColorInnerPage{
    color:#dea725;
}
.eventsListInnerPage{
    padding: 30px;
}
.eventsListInnerPage ul{
    padding: 0;
    list-style: none;
}
.eventsListInnerPage ul li{
    border-bottom: 1px solid #939393;
    padding-top: 15px;
    padding-bottom: 15px;
}
.eventsListInnerPage ul li p{
    margin-bottom: 0;
    font-size: 13px;
}
.contactDetailsInnerPage p{
    margin-bottom: 5px;
}
.formGroupInnerPage label{
    font-size: 13px;
}
.formGroupInnerPage input{
    width: 100%;
    margin-bottom: 10px;
    font-size: 13px;
    min-height: 30px;
}
.closeIconMnu {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}
.leftAlignBannerTxt {
    text-align: left!important;
    padding-left: 5%;
}
.rightAlignBannerTxt {
    text-align: right!important;
    padding-right: 5%;
}
.whiteText {
    color: rgb(255, 255, 255);
}
.colClickBox{
    margin-top: 63px;
    background: #fff;
    text-align: center;
    min-height: 165px;
}
.clickIconBOx{
    width: 100px;
    height: 100px;
    background-color: #fff;
    display: inline-block;
    border-radius: 200px;
    padding: 20px;
    margin-top: -53px;
    filter: drop-shadow(2px 2px 3px #c0c0c0);
}
.clickBoxIcon{
    width:60px;
}
.minHeight190 {
    min-height: 190px;
}
.shortCard{
    min-height: 300px;
}
.textColorBox1{
    background-color: #2099dc;
    text-align: center;
    margin-top: 15px;
    padding: 6px;
    color: #fff;
    font-weight: 600;
}
.width80 {
    width: 80%;
}
.borderRadius15 {
    border-radius: 15px;
}
.minHeight335 {
    min-height: 335px;
}
.barColor{
    background-color: #fff;
    border: none;
}
.barColor li{
    width: 14.28%;

}
.barColor.nav-tabs .nav-link {
    margin-bottom: 0;
    background: 0 0;
    border: none;
    border-top: 6px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    box-shadow: 3px 0px 2px -2px #c0c0c0;
    min-height: 65px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
}
.barColor.nav-tabs .nav-item.show .nav-link, .barColor.nav-tabs .nav-link.active {
    border-top: 6px solid #e0a726!important;
}
.titleHraderTable{
    background-color: #d5e6ff;
}
.MbaTab li{
    width: 20%;

}
.facilityBM20{
    margin-bottom:25px;
}
.facitextmargin{
    margin-top: 25px;
}
.barColor.col-4t li{
    width: 16%;

}
.barColor.col-5t li{
    width: 20%;

}
.orangeText{
    color: #e0a726;
}
.paddingTop1em{
    padding-top: 1em;
}
.paddingBottom1em{
    padding-bottom: 1em;
}
.marginBottom30px{
    margin-bottom: 30px;
}
.boxStyle11{
    border-bottom: 6px solid #e0a726;
    min-height: 300px;
}
.readmore1{
    font-weight: bold;
    color: #e0a726;
    cursor: pointer;
}
.textOrangeColor{
    color: #e0a726;
}
.clickIconBOx1{
    margin-top: -60px;
    display: inline-block;
}
.clickBoxIcon1{
    width: 120px;
}
.colClickBox1{
    margin-top: 63px;
    background: #fff;
    text-align: center;
    min-height: 400px;
    box-shadow: 0px 1px 4px #c0c0c0;
}
.colClickBox1 p{
    text-align: center !important;
}
.paddingLeftRight{
    padding-left: 15px;
    padding-right: 15px;
}
.blueBoxPatch{
    background-color: #122d50;
    border-radius: 4px;
    margin-top: 62px;
}
.minHeight400 {
    min-height: 400px;
}
.galleryTab img{
    margin-bottom: 30px;
}
.bgWhiteCorner{
    background-color: #fff;
    border-radius: 6px;
    height: 45px;
    padding-left: 15px;
    padding-top: 8px;
}
.mbaSmIcon{
    width:25px;
}
.bgOrange1{
    background-color: #e0a811;
}
.paddingclass {
    padding: 15px;
}
.tabViewHead div{
    margin-bottom: 0;
    background: 0 0;
    border: none;
    border-top: 6px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    /* box-shadow: 3px 0px 2px -2px #c0c0c0; */
    min-height: 65px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #fff;
    text-align: center;
    border-right: 1px solid #dcdcdc;
}
.tabTitle{
    font-size: 16px;
    font-weight: bold;
    margin-top: 14px;
    cursor: pointer;
}
.marginZero{
    margin: 0;
}
.paddingLeft15px {
    padding-left: 5em;
}
.staffMba .colClickBox1 {
    padding-left: 15%;
    padding-right: 15%;
    min-height: 411px;
}
.minHeight335px {
    padding-left: 15%;
    padding-right: 15%;
    min-height: 335px;
}
.buttonContainerCourse {
    position: absolute;
    bottom: 30px;
    right: 15px;
    max-width: 40%;
}
.buttonContainerCourse  img{
    width: 48%;
    margin-left: 1%;
    cursor: pointer;
}
.nbaCourse{
    max-height:335px;
    width:auto
}
.marginLeft12{
    margin-left: 12.5%
}
.marginBottom15px{
    margin-bottom: 15px;
}
.mobShow{
    display: none;
}
.wd50{
    width:20%!important;
}
.wd50Mba{
    width:16.66% !important;
}
.tabViewHead div.active {
    border-top: 6px solid #e0a811;
}
.closeIconMnuInner {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #dfa828;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}
.liGb li{
    background: #fcf7e9;
    padding: 9px;
    margin-bottom: 10px;
    text-align: justify;
}
.liVM{
    padding: 9px;
    margin-bottom: 10px;
    text-align: justify;
    margin-left: 12px;
}
.liVM li::marker{
    color: #1fa7a2;
    font-size: 28px;
}
.liGb li::marker {
    color: #8C2030;
    font-size: 28px;
}
.reg li::marker {
    color: #8C2030;
    font-size: 28px;
}
.reg1 li::marker {
    color: #8C2030;
    font-size: 28px;
    margin-bottom: 10px;
}
.liGb1{
    background: #fcf7e9;
    padding: 15px;
}
.accordion-button {
    background-color: #fff3e8;
}
.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #fff3e8;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.marginBottomZero{
    margin-bottom: 0!important;
}
.maxImgAwardIcon{
    width:200px
}
.studentC .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -40px;
}
.studentC .carousel-control-prev, .studentC .carousel-control-next{
    display: none;
}
.ImgIconSVG1{
    position: absolute;
    width: 48px;
    left: -15px;
}
.borderLeft12{
    border-left: 1px solid #c0c0c0;
}
.paddingLeft30 {
    padding-left: 30px;
    border-bottom: 1px solid #c0c0c0;
    padding-bottom: 12px;
}
.paddingLeft30-2{
    padding-left: 30px;
}
.boxShadowLeft {
    box-shadow: -10px 0px 10px -9px #aaaaaa;
}
.studentC .carousel-indicators button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    /* text-indent: -999px; */
    cursor: pointer;
    background-color: #c0c0c0;
    background-clip: padding-box;
    border: 1px solid #c0c0c0;
    opacity: .5;
    transition: opacity .6s ease;
    border-radius: 20px;
}

.studentC .carousel-indicators button.active {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    /* text-indent: -999px; */
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c0c0c0;
    opacity: 1;
}
.boxShadowNew {
    box-shadow: 4px 3px 5px #ececec;
}
.borderRightLine{
    border-right: 1px solid #dcdcdc;
}
.cursorPointer{
    cursor: pointer;
}
.closeBtn {
    background: #000;
    display: inline-block;
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    margin-left: 3px;
    top: 0;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    padding-left: 7px;
    cursor: pointer;
}
.formtitle {
    background: #dea725;
    color: #fff;
    text-align: center;
    min-height: 50px;
    padding-top: 7px;
    margin-bottom: 20px;
}
.form-group {
    margin-bottom: 15px;
}
.titleTxt{
    font-weight: bold;
    font-size: 19px;
    margin-top: 6px;
}
.registerBtn{
    background: #dea725;
    min-height: 42px;
    width: 100%;
    border: none;
    margin-top: 15px;
    color: #fff;
    font-weight: bold;
}
.enquireNowFormCont {
    position: fixed;
    top: 0;
    right: 0;
    min-height: 100vh;
    background: #FFF;
    z-index: 9999999;
    width: 101%;
    padding: 15px;
    max-width: 500px;
    margin-right: -105%;
    transition: all .5s ease;
}
.active11 {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.42);
    z-index: 9999;
    width: 100%;
}
.enableEnquire {
    margin-right: 0;
}
.SlideContentBox{
    width:565px;
    background-color: hsl(0deg 0% 0% / 80%);
    position: fixed;
    left:-568px;
    height: 300px;
    top:50%;
    transform: translateY(-50%);
    padding: 15px;
    color: #fff;
    transition:all .3s ease
}
.img2Content{
    left:190px
}
element.style {
    position: fixed;
    bottom: -1%;
    z-index: 999;
}
.blackText a{
    color:#000;
}
/* .MnuSocial {
    position: fixed;
    bottom: -4%;
    left: 15px;
    z-index: 9999;
} */
.MnuSocial .socialMediaLogo li {
    list-style: none;
    padding-left: 9px;
    color: #fff;
    border-bottom: none;
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
}
.MnuSocial .socialMediaLogo img {
    width: 26px;
    margin-right: 5px;
    cursor: pointer;
}
.carousel-indicators [data-bs-target] {
    background-color: #464646;
}
.enquireBtn {
    border: none;
    border-bottom: none;
    cursor: pointer;
    width: 170px;
    height: 34px;
    overflow: hidden;
    background: #e7af18;
    color: #ffffff;
    padding: 2px 0px 2px 0px;
    position: fixed;
    top: calc(50% - 42px);
    right: -68px;
    text-align: center;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 9999999;
    font-size: 18px;
}
.mobileBanner{
    display: none;
}
.bannerTextMob {
    position: absolute;
    bottom: 7%;
    width: 90%;
    background: rgb(0 0 0 / 63%);
    color: #fff;
    left: 5%;
    padding: 5px;
}
.readButton {
    margin-bottom: 22px;
    display: block;
    font-weight: 900;
    color: #e0ad50;
}
.imgIconCenterTop{
    width: 150px;
    margin-top: -75px;
}












@media only screen and (max-width: 780px) {
    .blinkfont{
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-top:0px;
       }
    .MobPlaceMarginTop{
   margin-top: 25px;    
}
    .placemetBox {
        width: 50%;
    }
    .popupImg{
        width: 80%;
    }
    .uTubemarginT{
        margin-top:15px;
    }
    .Textleft{
        text-align: center;
    }
    .innerPageLogo {
        max-width: 290px;
    }    
    .nbaCourse{
        max-height:auto;
        width:100%
    }
    .minHeight335px {
        padding-left: 5%;
        padding-right: 5%;
        min-height: auto;
    }
    .minHeight335px {
        min-height: auto;
    }
    /* .minHeight190 {
        min-height: auto;
    } */
    .staffMba .colClickBox1 {
        padding-left: 5%;
        padding-right: 5%;
        min-height: auto;
        padding-bottom: 15px;
    }
    .innerPageMnuIcon {
        width: 39px;
        margin-top: 15px;
        cursor: pointer;
    }
    .bannerTitleAbout {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 61%;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 18px;
    }
    .marginLeft12{
        margin-left: 0
    }
    .positionInheritMob {
        position: inherit;
    }
    .circleJourney {
        width: 80px;
        position: absolute;
        margin-left: -21px;
        margin-top: 10px;
    }
    .circleJourneySmall {
        width: 60px;
        position: absolute;
        margin-left: -12px;
        margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
    }
    .marginBottom2em {
        margin-bottom: 1em;
    }
    .marginBottom2em {
    margin-bottom: 1em;
}
.buttonStyle1 {
    width: 100%;
    background: #dea725;
    border: none;
    color: #fff;
    font-size: 13px;
    height: 30px;
}
 .mobTextCenter{
    text-align: center;
 }  
 .fulMenuMain {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: #dea725;
    z-index: 9999;
    padding-top: 15%;
    padding-left: 5%;
    padding-right: 5%;
} 
.HomeSubmenuContainerInnerPage {
    position: fixed;
    width: 100%;
    left: -101%;
    top: 0;
    background-color: #ffffff;
    transition: all .3s ease;
    z-index: 99999;
    transform: none;
    height: 100vh;
    padding-top: 15%;
    padding-left: 5%;
    padding-right: 5%;
}
.activeSubMenuInnerPage {
    left: 0;
}
.fulMenuMain ul li {
    font-size: 15px;
}
.mobHide{
    display: none;
}
.paddingCommonbox {
    padding-left: 15px;
}
.paddingLeft70px {
    padding-left: 0;
}
.backgroundGrey {
    padding-bottom: 1em;
    padding-top: 1em;
}
.buttonContainerCourse {
    position: absolute;
    bottom: 5px;
    right: 15px;
    max-width: 100%;
}
.bgWhiteCorner {
    margin-bottom: 10px;
    position: relative;
}
.mobShow{
    display: block;
}
.patchTwoIconContainer {
    width: 100%;
    margin-left: 0;
    padding-top: 1em;
    padding-bottom: 1em;
}
.homeIcon {
    width: 45px;
}
.testimonialImg {
    width: 30%;
    margin-top: 1em;
}
.barColor li{
    width: 50%;

}
.wd50{
    width:max-content!important;
}
.wd50Mba {
    width: 70.66% !important;
}
.tabTitle {
    text-align: left;
    margin-top: 8px;
}
.tabViewHead div {
    min-height: 40px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 10px;
}
.mpaddingTop0{
    padding-top: 0;
}
.bannerText{
    display: none;
}
.SlideContentBox{
    display: none;
}
.paddingSpace {
    padding-top: 1em;
    padding-bottom: 1em;
}
.mobileBanner{
    display: block;
}
.mobPad1{
    padding-left: 10px;
    padding-right: 10px;
}
.paddingLeft70pxImp{
    padding-left: 65px;
}
.circleAboutIconMob1 {
    width: 100px;
    position: relative;
    margin-top: 13px;
    margin-left: -7px;
    margin-bottom: -10px;
}
.socialMediaLogo {
    padding: 0;
    display: inline-flex;
    text-align: center;
}
.paddMgZero {
    padding: 0;
    margin: 0;
}
.mobBottomFooter{
    background-color: #000;
    text-align: center;
    color: #fff;
    padding: 15px;
}
.paddingTop3em {
    padding-top: 1em;
}
.mobUlNone {
    list-style: none;
    padding: 0;
}
.noBoxShM{
    box-shadow: none;
}
.paddingBottom3em {
    padding-bottom: 1.5em;
}
.mpaddingBottom0 {
    padding-bottom: 0;
}
.MpaddingLeft1em {
    padding-left: 1em;
}
.tabViewHead div.active {
    border-top: 6px solid #e0a811;
    background: #e0a811;
    color: #fff;
}
.width80 {
    width: 100%;
}
.mobHeightAuto{
    min-height: auto;
}
.colClickBox {
    margin-top: 44px;
}
.paddingclassMobNew{
    padding-left: 12px;
    padding-right: 12px;
}
.iconBoxMinHeight {
    min-height: 144px;
    margin-bottom: 12px;
}
.imgIconCenterTop {
    width: 100px;
    margin-top: 0;
}
.textLeftM{
    text-align: left!important;
}
.barColor.transparentBackground li {
    width: max-content;
    margin-right: 10px;
}
.barColor.transparentBackground .nav-link {
    margin-bottom: 0;
    background: 0 0;
    border: 1px solid #dcdcdc;
    border-top: 6px solid transparent;
    border-radius: 12px;
    width: 100%;
    box-shadow: none;
    min-height: 65px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 10px;
    margin-top: 15px;
    margin-left: 11px;
}
.transparentBackground{
    background-color: transparent;
}
.barColor.transparentBackground .nav-link.active {
    border-top: 6px solid #e0a726!important;
    background: #e0a726;
    color: #fff;
}
.paddingCommonbox1 {
    padding-left: 15px;
    padding-top: 0;
    padding-bottom: 11px;
}
  }