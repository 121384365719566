.para {
    font-size: 17px;
    text-align: justify;
    line-height: 26px;
    margin: 1em 0px !important;
    color:white!important;
}
.para2ivy {
    font-size: 17px;
    text-align: justify;
    line-height: 26px;
   
    color:white!important;
}
.paddingclassHostel {
    padding: 3em 0px !important;
}
.bg1{
    background:rgb(71, 148, 112);
}
.liclass{
     font-size: 16px;
    text-align: justify;
    line-height: 26px;
    
    color:white!important;
}
.fontbig2 {
    font-size: 32px;
    color: white;
  
    font-weight: bold;
    margin-bottom: 1em;
}
.bg2{
    background: rgb(29, 56, 87)
}
.ultopmargin{
    margin-top:3em;
}
.roompaddingbox{
    padding:3em 5em;
}
.contactbg{
    background-image: url(../../assets/images/ivyHostelImg/contactivy.png) no-repeat;
    background-size: cover;
    height: 70vh;
}
.labeldesign{
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 1em;
}
.formpaddingbox{
  padding: 3em 4em;
}
.form-control {
    box-shadow: none;
    border-radius: 0;
    border-radius: 10px;
    border: 1px solid #459571;
    background: #459571;
    margin-bottom:2em;
}
.inbox{
padding: 0px;
    display: inline-flex;
    

}
.width1{
    width: 48%;
    margin-right: 5%;
     text-align: center;
}
.width2{
        width: 47%;
         text-align: center;
}
.btnclassnew{
    text-align: center;
    padding: 2% 3em;
    font-size: 16px;
    font-weight: bold;
}
.img_typeofrrom{
    margin-bottom:3em;
    width:100%;
}
.marginroombox{
    margin-top:7em;
}
.imgSocialIcon {
    width: 11%;
}
.locationbox{
    position: absolute;
    top: 14em;
    left: 20em;
    width: 40%;
}
.locatiomimg{
    width:22%;
}
.textbox{
    background: rgb(29, 56, 87);
        padding: 1em;
}
.fontbanner{
    font-size:30px;
    font-weight:bold;
    color:white;
   
}
.fontbanner1{
    font-size:30px;
    font-weight:bold;
    color:white;
    border-left :2px solid rgb(71, 148, 112);
}
.imgSocialIcon {
    width: 30px;
    margin-top: 15px;
    margin-left: 5px;
}
.ivyshowmob{
   display:none; 
}
.ivyshowdesk{
   display:block; 
}
@media only screen and (max-width: 680px) {
    .ivyshowmob{
   display:block; 
}
.ivyshowdesk{
   display:none; 
}
.imgSocialIcon {
    width: 18px;
    margin-top: 15px;
    margin-left: 5px;
}
    .locationbox {
    position: absolute;
    top: 4em;
    left: 1em;
    width: 91%;
}
.locatiomimg {
    width: 10%;
}
.textbox {
    background: rgb(29, 56, 87);
    padding: 2%;
}
.fontbanner {
    font-size: 16px;
    font-weight: bold;
    color: white;
}
.fontbanner1{
    font-size:16px;
    font-weight:bold;
    color:white;
    border-left :2px solid rgb(71, 148, 112);
}
.para {
    font-size: 14px;
    text-align: justify;
    line-height: 22px;
    margin: 2% 0px !important;
    color: white!important;
}
.paddingclassHostel {
    padding: 2em 0px !important;
}
.img_typeofrrom {
    margin-bottom: 2em;
    width: 79%;
}
.marginroombox {
    margin-top: 0em;
}
.roompaddingbox {
    padding: 1em 1em;
}
.fontbig2 {
    text-align: center;
    font-size: 22px;
    color: white;
    font-weight: bold;
    margin-bottom: 1em;
}
.liclass {
    font-size: 14px;
    text-align: justify;
    line-height: 24px;
    color: white!important;
}
.ultopmargin {
    margin-top: 2em;
}
.para2ivy {
    font-size: 14px;
    text-align: justify;
    line-height: 24px;
    color: white!important;
}
.inbox {
    padding: 0px;
    display: inline-flex;
}
.width1 {
    width: 50%;
    margin-right: 5%;
    text-align: center;
}
.labeldesign {
    font-size: 14px;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 1em;
}
.form-control {
    box-shadow: none;
    border-radius: 0;
    border-radius: 10px;
    border: 1px solid #459571;
    background: #459571;
    margin-bottom: 1em;
}
.width2 {
    width: 50%;
    text-align: center;
}
.formpaddingbox {
    padding: 0em 1em;
}
}